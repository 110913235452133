:root {
--cat-blakck: #191919;
--cat-grey2: #2A2A2A;
--cat-grey-link: #4f4f4f;
--cat-border-bot: rgba(134, 134, 134 .3);
 --cat-musztarda: #FFB763;
}

  
/* General Text Formatting */
/* h1 {
	color:#F4F4F4;
	font-size:3.8rem;
	line-height:.95;
}
h2 {
	font-size:3rem;
	color:#fff;
}
h3 {
	font-size:1.8rem;
	color:#fff;
}
h4 {
	font-size:1.4rem;
	color:#f4f4f4;
	font-weight:500;
}
h5 {
	font-size:1.6rem;
}
h6 {
	font-size:1.4rem;
	color:#fff;
	font-weight:500;
}
strong {
	font-weight:600;
} */
.heading-animation {
	opacity:0;
}
.animate {
	animation:1s ease 0s 1 normal forwards move;
}
@keyframes move {
	0% {
		transform:translateY(40px);
		-webkit-transform:translateY(40px);
		-moz-transform:translateY(40px);
		-ms-transform:translateY(40px);
		-o-transform:translateY(40px);
		opacity:0;
	}
	100% {
		transform:translateY(0);
		-webkit-transform:translateY(0);
		-moz-transform:translateY(0);
		-ms-transform:translateY(0);
		-o-transform:translateY(0);
		opacity:1;
	}
}
 
small {
	font-size: 85.71429%;
}
 
/* Global Link style */
/* a {
	color:#4F4F4F;
	text-decoration: none;
	outline: none;
	transition:all 0.4s ease;
	-webkit-transition:all 0.4s ease;
}
a:hover, a:focus {
	outline:none;
	text-decoration: none;
} */
 
 /* button {
	font-weight:600;
	padding:0;
	display:inline-block;
	background:transparent;
	border:none;
	cursor:pointer;
} */
button:focus {
	outline:none;
}
.buttonWeb, .btnWeb {
	font-weight:500;
	display:inline-block;
	text-align:center;	
	border-radius:32px;
	font-size: 1.2rem;
	padding:5px 12px;
	transition:all 0.4s ease;
	-webkit-transition:all 0.4s ease;
}
.buttonWeb {
	color:#191919;
	/* background:#fff; */
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
	-ms-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
	-o-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}
.buttonWeb:hover {
	background:#F0F0F0;
}
.btnWeb {
	color:#191919ff;
	background:#ffb764;
	border:1px solid #ffb764;;

}
.btnWeb:hover {
    background:#ffb764;
opacity: .7;
}
.outline-btnWeb {
	border: 1px solid #191919;
	color: #191919;
	background:#fff;
	position:relative;
}
.outline-btnWeb:hover, .outline-btnWeb:focus {
	color:#fff;
}
.outline-btnWeb i {
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-51px, -50%);
	-webkit-transform:translate(-51px, -50%);
	-moz-transform:translate(-51px, -50%);
	-ms-transform:translate(-51px, -50%);
	-o-transform:translate(-51px, -50%);
}
.outline-btnWeb .hover-img {
	display:none;
}
.outline-btnWeb:hover .hover-img, .outline-btnWeb:focus .hover-img {
	display:block;
}
.outline-btnWeb:hover .normal-img, .outline-btnWeb:focus .normal-img {
	display:none;
}  
.sticky-black {
	font-size:1.2rem;
	background: #1E1D20;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
	position:fixed;
	left:50%;
	bottom:30px;
	transform:translateX(-50%);
	-webkit-transform:translateX(-50%);
	-moz-transform:translateX(-50%);
	-ms-transform:translateX(-50%);
	-o-transform:translateX(-50%);
	z-index:8;
}
/* label {
	font-size:1.4rem;
	display:block;
	color:#fff;
	font-weight:500;
}
input {
	padding:5px 10px;
	font-family:"aktiv-grotesk", sans-serif;
	display:block;
	width:100%;
}
input:focus {
	outline:none;
} */


/*  */

#wrapper {
    overflow: hidden;
    position: relative;
}

.parallax-window {
    min-height: 400px;
    background: transparent;
}
.parallax-container {
    height: 500px;
    width: 100%;
}

.shadow {
    position: relative;
}
.banner {
    min-height: 541px;
    padding-top: 70px;
    position: relative;
    padding-bottom: 30px;
}
 

.shadow:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.5;
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
}
*, :before, :after {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.banner .containerAW {
    min-height: 541px;
    position: relative;
}



.containerAW {
    width: 100%;
    max-width: 1230px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
}

.banner-text {
    position: absolute;
    left: 0;
    bottom: 26px;
    width: 100%;
    padding: 0 20px;
}

.heading-main {
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 24px;
    margin-bottom: 30px;
}


@media only screen and (min-width: 1366px) { 

    .heading-main {
        margin-bottom: 45px;
    }
.banner {
    min-height: 809px;
}

.banner .containerAW {
    min-height: 809px;
}
.banner-text h1 {
    font-size: 10rem;
    letter-spacing: -1.5px;
    word-spacing: -6px;
    max-width: 800px;
}
.banner-text .enter-btn {
    margin-left: 10px;
}
.select-wrap {
    bottom: 8px;
    right: 25px;
}
}


@media only screen and (min-width: 768px) { 
.banner {
    padding-bottom: 0;
}
.banner-text {
    padding: 0 10px;
    bottom: 56px;
}
.heading-main {
    margin-bottom: 45px;
}
body .banner-text h1 {
    max-width: 900px !important;
}
.banner-text h1 {
    max-width: 435px;
}
.banner-text .enter-btn {
    margin-bottom: 0;
}
.banner-text .enter-btn {
    max-width: 143px;
}
.select-wrap {
    bottom: 15px;
}
}


@media only screen and (min-width: 375px) { 


.banner-text h1 {
    max-width: 300px;
    letter-spacing: -.8px;
}

/* h1 {
    font-size: 4.5rem;
} */

}





@media only screen and (max-width: 767px) {
.banner-text h1 {
    font-size: 3.3rem;
    line-height: 3.75rem;
    opacity: 0.75;
}
}
 


@media only screen and (min-width: 576px){
    .banner-text h1 {
        max-width: 360px;
        letter-spacing: -1px;
        word-spacing: -2px;
        font-size: 5.5rem;
    }
    }
 
    

 
@media only screen and (min-width: 375px){
/* body:not(.features) h1 {
    font-size: 6.5rem;
    line-height: 1.0;
} */
.banner-text h1 {
    max-width: 300px;
    letter-spacing: -.8px;
}
}


 
 



.banner-text h1 {
    margin-bottom: 0;
    letter-spacing: -.5px;
    max-width: 280px;
}






.banner-text h1 {
    margin-bottom: 0;
    letter-spacing: -.5px;
    max-width: 280px;
}
 

.heading-animation {
    opacity: 0;
}
 



.banner-text .enter-btn {
    width: 100%;
    padding: 13px 12px;
}
.enter-btn {
    font-weight: 600;
    min-width: 80px;
}
/* 

.button {
    color: #191919;
    background: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    -ms-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    -o-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}
.button, .btn {
    font-weight: 500;
    display: inline-block;
    text-align: center;
    border-radius: 32px;
    font-size: 1.2rem;
    padding: 5px 12px;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
}
a {
    color: #4F4F4F;
    text-decoration: none;
    outline: none;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
}
a:hover, a:focus {
    outline: none;
    text-decoration: none;
}
.button:hover {
    background: #F0F0F0;
} */

.select-wrap {
    position: absolute;
    right: 20px;
    z-index: 8;
    bottom: -42px;
}

.banner .primary-container {
    padding: 0 20px;
    position: relative;
}
.primary-container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
}

/* koniec hero */



/* @media only screen and (min-width: 576px) { 
.button {
    font-size: 1.4rem;
}}

@media only screen and (min-width: 1366px) { 
.button {
    font-size: 1.2rem;
}
} */

/*  */


@media (min-width: 1024px) {
#content {
    -webkit-flex: 1 auto;
    -ms-flex: 1 auto;
    flex: 1 auto;
    min-width: 1%;
} }

#content {
    min-height: 100vh;
}
@media (min-width: 1200px) {
.content-header {
    padding-left: 5rem;
    padding-right: 5rem;
}

}
@media (min-width: 480px) { 
.content-header {
    min-height: 40rem;
    padding-top: 7.5rem;
}
}

@media (min-width: 530px) {
.content-header {
    padding: 7rem 3.5rem 1rem;
}
}

.content-header {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 32rem;
    padding: 7rem 1.5rem 1rem;
    position: relative;
}
.uk-light {
    color: rgba(255, 255, 255, 0.9);
}

.landing-blur {
    color: white;
    position: absolute;
    z-index: 0;
    right: 0;
    top: 0;
}
  .uk-hidden {
    display: none !important;
}


svg:not(:root) {
    overflow: hidden;
}
.landing-blur {
    color: white;
    position: absolute;
    z-index: 0;
    right: 0;
    top: 0;
}
.uk-svg {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}
.uk-svg, .uk-svg:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
    fill: currentcolor;
}
@supports (display: block) {
svg {
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
} }

audio, canvas, iframe, img, svg, video {
    vertical-align: middle;
}

@media (min-width: 1600px) { 
.content-header h1 {
    max-width: 55%;
}
}
@media (min-width: 1200px) { 
.content-header h1 {
    max-width: 65%;
}
}
.content-header h1 {
    margin: 0;
    transition: all 1s ease;
    opacity: 1;
}

.uk-light h1 {
    color: #fff;
}

/*  */



/*  content about-banner*/


.head-main h1 {
    max-width: 270px;
    margin: 0 auto;
    }
   


.about-banner {
    background: #191919  no-repeat right top;
    background-size: 100% auto;
    min-height: 275px;
    padding-top: 0;
}



@media only screen and (min-width: 375px) {
    .head-main h1 {
        max-width: 320px;
    }

  
    }



@media only screen and (min-width: 768px) {
.about-banner, .about-banner .containerAW {
    min-height: 340px;
}
 
.about-banner .containerAW {
    min-height: 275px;
    position: relative;
}
}


 

    @media only screen and (min-width: 1200px) { 
        .about-banner .containerAW {
            min-height: 460px;
        }
    
        .head-main h1 {
            max-width: 700px;
            font-size: 10rem;
        }
        .about-banner {
            min-height: 460px;
        }
    
        }



    
    .head-main {
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    width: 100%;
    text-align: center;
    }
    
    
    
   



/*  */

/* speech-wrapper  */
.speech-wrapper {
    margin-top: 120px;
}
@media only screen and (min-width: 1366px) {  
.speech-wrapper {
    margin-bottom: 186px;
}
.speech-wrapper h3 {
    font-size: 4rem;
    padding-bottom: 20px;
    margin-bottom: 53px;
}
}

.speech-wrapper {
    margin-bottom: 98px;
}

 

.speech-wrapper h3 {
    color: #242422;
    font-size: 3rem;
    letter-spacing: -.2px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 46px;
    padding-bottom: 10px;
}

@media only screen and (min-width: 1440px){
.webby-wrapper .primary-container, .showcase .primary-container {
    padding-left: 122px;
    max-width: 1440px;
}}
@media only screen and (min-width: 1200px){
.webby-wrapper .primary-container, .showcase .primary-container {
    max-width: 1230px;
    padding-left: 20px;
}}
@media only screen and (min-width: 1366px) { 
.primary-container {
    padding-left: 39px;
    padding-right: 39px;
}}
.primary-container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
}





/*  */


/* tour  */




.talks-tour {
    padding: 63px 0 100px;
}









.stop {
    font-size: 1.2rem;
    color: #000;
    display: block;
    margin-bottom: 5px;
}
.tour-text p {
    margin-bottom: 45px;
}
p {
    line-height: 1.7;
}

.tour-img {
    /* margin-bottom: 34px; */
}



 
.view-sch {
    width: 100%;
    padding: 15px 12px;
}

.tr-top h2 {
    color: #242422;
    /* margin-bottom: 22px; */
    letter-spacing: -.5px;
}

.tr-top {
  display: flex;
  justify-content: space-between;
}
.infomobile .tr-top {
  display: none;
 
}


.tr-top p {
margin: auto 0;
}


  .talks-tour .tour-img.shadow:before {
    background: none !important;
}
  .tour-img img {
    object-fit: contain;
}
.tour-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
    -webkit-border-radius: 3px;
}

.tour-text h2 {
    color: #191919;
    font-size: 4.5rem;
    line-height: .95;
    letter-spacing: -.5px;
    margin-bottom: 27px;
    max-width: 280px;
}

.tour-btn {justify-content: space-between;
    /* width: 100%; */
    color: #191919;
    border: 1px solid #000;
    /* padding: 12px;
    font-size: 1.4rem; */
    box-shadow: none;
    -webkit-box-shadow: none;

    border-radius: 62px;
    /* padding: 5px 12px 3px; */
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;




}
.tour-btn:hover {
    color: #fff;
    background: #000;
}






@media only screen and (min-width: 768px){
    .tr-top,  .infomobile .tr-top {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
        border-bottom: 2px solid rgba(134, 134, 134, 0.3);
        margin-bottom: 40px;
    }

  

    .tr-top h2 {
        margin-bottom: 0;
    }
    .view-sch {
        max-width: 239px;
        padding: 12px;
    }
    
    .tour-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: row-reverse;
    }
    
    .tour-img {
        width: 38%;
    }
    
    .tour-text {
        align-self: flex-end;
        width: 60%;
    }
    .tour-text p {
        max-width: 360px;
    }
    
    /* .tour-btn {
        max-width: 153px;
    } */
    }
    

    @media only screen and (min-width: 1200px){
        .tr-top {
            /* padding-bottom: 28px;
            margin-bottom: 111px; */
        }}
        

    @media only screen and (min-width: 1366px) {  
        .talks-tour {
            /* padding: 68px 0 130px; */
        }
        .tour-text h2 {
            /* font-size: 8rem; */
            max-width: 485px;
        }
        .tour-btn {
            font-size: 16px;
        }
        
        }

@media only screen and (min-width: 1440px) { 
    .tour-img {
        width: 42%;
    }
    .tour-text {
        width: 51%;
    }
    .tour-text h2 {
        max-width: 590px;
        letter-spacing: -2.1px;
        margin-bottom: 36px;
    }
    .tour-text h2 {
        /* font-size: 10rem; */
    }
    
    .tour-text p {
        margin-bottom: 72px;
    }
    }
     


/*  */
