:root {
  --cat-blakck: #191919;
  --cat-grey2: #2a2a2a;
  --cat-grey-link: #4f4f4f;
  --cat-border-bot: rgba(134, 134, 134 .3);
  --cat-musztarda: #ffb763;
}

.animate {
  animation: 1s forwards move;
}

@keyframes move {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

small {
  font-size: 85.7143%;
}

button:focus {
  outline: none;
}

.buttonWeb, .btnWeb {
  text-align: center;
  border-radius: 32px;
  padding: 5px 12px;
  font-size: 1.2rem;
  font-weight: 500;
  transition: all .4s;
  display: inline-block;
}

.buttonWeb {
  color: #191919;
  -ms-box-shadow: 0px 4px 20px #00000040;
  -o-box-shadow: 0px 4px 20px #00000040;
  box-shadow: 0 4px 20px #00000040;
}

.buttonWeb:hover {
  background: #f0f0f0;
}

.btnWeb {
  color: #191919;
  background: #ffb764;
  border: 1px solid #ffb764;
}

.btnWeb:hover {
  opacity: .7;
  background: #ffb764;
}

.outline-btnWeb {
  color: #191919;
  background: #fff;
  border: 1px solid #191919;
  position: relative;
}

.outline-btnWeb:hover, .outline-btnWeb:focus {
  color: #fff;
}

.outline-btnWeb i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-51px, -50%);
}

.outline-btnWeb .hover-img {
  display: none;
}

.outline-btnWeb:hover .hover-img, .outline-btnWeb:focus .hover-img {
  display: block;
}

.outline-btnWeb:hover .normal-img, .outline-btnWeb:focus .normal-img {
  display: none;
}

.sticky-black {
  z-index: 8;
  background: #1e1d20;
  font-size: 1.2rem;
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 4px 20px #00000040;
}

#wrapper {
  position: relative;
  overflow: hidden;
}

.parallax-window {
  background: none;
  min-height: 400px;
}

.parallax-container {
  width: 100%;
  height: 500px;
}

.shadow {
  position: relative;
}

.banner {
  min-height: 541px;
  padding-top: 70px;
  padding-bottom: 30px;
  position: relative;
}

.shadow:before {
  content: "";
  opacity: .5;
  background: linear-gradient(#000 0%, #0000 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
}

*, :before, :after {
  box-sizing: border-box;
}

.banner .containerAW {
  min-height: 541px;
  position: relative;
}

.containerAW {
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.banner-text {
  width: 100%;
  padding: 0 20px;
  position: absolute;
  bottom: 26px;
  left: 0;
}

.heading-main {
  border-bottom: 1px solid #f4f4f4;
  margin-bottom: 30px;
  padding-bottom: 24px;
}

@media only screen and (min-width: 1366px) {
  .heading-main {
    margin-bottom: 45px;
  }

  .banner, .banner .containerAW {
    min-height: 809px;
  }

  .banner-text h1 {
    letter-spacing: -1.5px;
    word-spacing: -6px;
    max-width: 800px;
    font-size: 10rem;
  }

  .banner-text .enter-btn {
    margin-left: 10px;
  }

  .select-wrap {
    bottom: 8px;
    right: 25px;
  }
}

@media only screen and (min-width: 768px) {
  .banner {
    padding-bottom: 0;
  }

  .banner-text {
    padding: 0 10px;
    bottom: 56px;
  }

  .heading-main {
    margin-bottom: 45px;
  }

  body .banner-text h1 {
    max-width: 900px !important;
  }

  .banner-text h1 {
    max-width: 435px;
  }

  .banner-text .enter-btn {
    max-width: 143px;
    margin-bottom: 0;
  }

  .select-wrap {
    bottom: 15px;
  }
}

@media only screen and (min-width: 375px) {
  .banner-text h1 {
    letter-spacing: -.8px;
    max-width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-text h1 {
    opacity: .75;
    font-size: 3.3rem;
    line-height: 3.75rem;
  }
}

@media only screen and (min-width: 576px) {
  .banner-text h1 {
    letter-spacing: -1px;
    word-spacing: -2px;
    max-width: 360px;
    font-size: 5.5rem;
  }
}

@media only screen and (min-width: 375px) {
  .banner-text h1 {
    letter-spacing: -.8px;
    max-width: 300px;
  }
}

.banner-text h1 {
  letter-spacing: -.5px;
  letter-spacing: -.5px;
  max-width: 280px;
  margin-bottom: 0;
}

.heading-animation {
  opacity: 0;
}

.banner-text .enter-btn {
  width: 100%;
  padding: 13px 12px;
}

.enter-btn {
  min-width: 80px;
  font-weight: 600;
}

.select-wrap {
  z-index: 8;
  position: absolute;
  bottom: -42px;
  right: 20px;
}

.banner .primary-container {
  padding: 0 20px;
  position: relative;
}

@media (min-width: 1024px) {
  #content {
    flex: auto;
    min-width: 1%;
  }
}

#content {
  min-height: 100vh;
}

@media (min-width: 1200px) {
  .content-header {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media (min-width: 480px) {
  .content-header {
    min-height: 40rem;
    padding-top: 7.5rem;
  }
}

@media (min-width: 530px) {
  .content-header {
    padding: 7rem 3.5rem 1rem;
  }
}

.content-header {
  box-sizing: border-box;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 32rem;
  padding: 7rem 1.5rem 1rem;
  display: flex;
  position: relative;
}

.uk-light {
  color: #ffffffe6;
}

.uk-hidden {
  display: none !important;
}

svg:not(:root) {
  overflow: hidden;
}

.landing-blur {
  color: #fff;
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.uk-svg {
  transform: translate(0);
}

.uk-svg, .uk-svg:not(.uk-preserve) [fill*="#"]:not(.uk-preserve) {
  fill: currentColor;
}

@supports (display: block) {
  svg {
    box-sizing: border-box;
    max-width: 100%;
    height: auto;
  }
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

@media (min-width: 1600px) {
  .content-header h1 {
    max-width: 55%;
  }
}

@media (min-width: 1200px) {
  .content-header h1 {
    max-width: 65%;
  }
}

.content-header h1 {
  opacity: 1;
  margin: 0;
  transition: all 1s;
}

.uk-light h1 {
  color: #fff;
}

.head-main h1 {
  max-width: 270px;
  margin: 0 auto;
}

.about-banner {
  background: #191919 100% 0 / 100% no-repeat;
  min-height: 275px;
  padding-top: 0;
}

@media only screen and (min-width: 375px) {
  .head-main h1 {
    max-width: 320px;
  }
}

@media only screen and (min-width: 768px) {
  .about-banner, .about-banner .containerAW {
    min-height: 340px;
  }

  .about-banner .containerAW {
    min-height: 275px;
    position: relative;
  }
}

@media only screen and (min-width: 1200px) {
  .about-banner .containerAW {
    min-height: 460px;
  }

  .head-main h1 {
    max-width: 700px;
    font-size: 10rem;
  }

  .about-banner {
    min-height: 460px;
  }
}

.head-main {
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
}

.speech-wrapper {
  margin-top: 120px;
}

@media only screen and (min-width: 1366px) {
  .speech-wrapper {
    margin-bottom: 186px;
  }

  .speech-wrapper h3 {
    margin-bottom: 53px;
    padding-bottom: 20px;
    font-size: 4rem;
  }
}

.speech-wrapper {
  margin-bottom: 98px;
}

.speech-wrapper h3 {
  color: #242422;
  letter-spacing: -.2px;
  border-bottom: 1px solid #0003;
  margin-bottom: 46px;
  padding-bottom: 10px;
  font-size: 3rem;
}

@media only screen and (min-width: 1440px) {
  .webby-wrapper .primary-container, .showcase .primary-container {
    max-width: 1440px;
    padding-left: 122px;
  }
}

@media only screen and (min-width: 1200px) {
  .webby-wrapper .primary-container, .showcase .primary-container {
    max-width: 1230px;
    padding-left: 20px;
  }
}

@media only screen and (min-width: 1366px) {
  .primary-container {
    padding-left: 39px;
    padding-right: 39px;
  }
}

.primary-container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
}

.talks-tour {
  padding: 63px 0 100px;
}

.stop {
  color: #000;
  margin-bottom: 5px;
  font-size: 1.2rem;
  display: block;
}

.tour-text p {
  margin-bottom: 45px;
}

p {
  line-height: 1.7;
}

.view-sch {
  width: 100%;
  padding: 15px 12px;
}

.tr-top h2 {
  color: #242422;
  letter-spacing: -.5px;
}

.tr-top {
  justify-content: space-between;
  display: flex;
}

.infomobile .tr-top {
  display: none;
}

.tr-top p {
  margin: auto 0;
}

.talks-tour .tour-img.shadow:before {
  background: none !important;
}

.tour-img img {
  object-fit: contain;
  object-fit: cover;
  border-radius: 3px;
  width: 100%;
  height: 100%;
}

.tour-text h2 {
  color: #191919;
  letter-spacing: -.5px;
  max-width: 280px;
  margin-bottom: 27px;
  font-size: 4.5rem;
  line-height: .95;
}

.tour-btn {
  color: #191919;
  box-shadow: none;
  -webkit-box-shadow: none;
  border: 1px solid #000;
  border-radius: 62px;
  justify-content: space-between;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
}

.tour-btn:hover {
  color: #fff;
  background: #000;
}

@media only screen and (min-width: 768px) {
  .tr-top, .infomobile .tr-top {
    border-bottom: 2px solid #8686864d;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    padding-bottom: 15px;
    display: flex;
  }

  .tr-top h2 {
    margin-bottom: 0;
  }

  .view-sch {
    max-width: 239px;
    padding: 12px;
  }

  .tour-wrapper {
    flex-flow: row-reverse wrap;
    justify-content: space-between;
    display: flex;
  }

  .tour-img {
    width: 38%;
  }

  .tour-text {
    align-self: flex-end;
    width: 60%;
  }

  .tour-text p {
    max-width: 360px;
  }
}

@media only screen and (min-width: 1366px) {
  .tour-text h2 {
    max-width: 485px;
  }

  .tour-btn {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1440px) {
  .tour-img {
    width: 42%;
  }

  .tour-text {
    width: 51%;
  }

  .tour-text h2 {
    letter-spacing: -2.1px;
    max-width: 590px;
    margin-bottom: 36px;
  }

  .tour-text p {
    margin-bottom: 72px;
  }
}

/*# sourceMappingURL=index.66f99660.css.map */
